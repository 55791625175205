import {getExchanges} from '../utils/api'
import {onMounted, ref} from 'vue'

export default function fetchExchanges(){
  const moneyFinish = ref(false)
  const money = ref({
    dollarExchangeRate: 'N/A',
    poundExchangeRate: 'N/A',
    euroExchangeRate: 'N/A',
    yenExchangeRate: 'N/A',
    cadExchangeRate: 'N/A',
    wonExchangeRate: 'N/A',
    hkdExchangeRate: 'N/A',
    updateTime: 'N/A'
  })
  const btc = ref('N/A')
  const getMoney = async () => {
    const {
      美元: dollar = {},
      英镑: pound = {},
      欧元: euro = {},
      日元: yen = {},
      加拿大元: cad = {},
      韩国元: won = {},
      港币: hkd = {}
    } = await getExchanges.exchange().then((res) => {
      return res.data.data[0]
    })

    let {bankConversionPri: dollarExchangeRate, date, time} = dollar
    let {bankConversionPri: poundExchangeRate} = pound
    let {bankConversionPri: euroExchangeRate} = euro
    let {bankConversionPri: yenExchangeRate} = yen
    let {bankConversionPri: cadExchangeRate} = cad
    let {bankConversionPri: wonExchangeRate} = won
    let {bankConversionPri: hkdExchangeRate} = hkd

    dollarExchangeRate = (dollarExchangeRate/100).toFixed(2)
    poundExchangeRate = (poundExchangeRate/100).toFixed(2)
    euroExchangeRate = (euroExchangeRate/100).toFixed(2)
    yenExchangeRate = (yenExchangeRate/100).toFixed(2)
    cadExchangeRate = (cadExchangeRate/100).toFixed(2)
    wonExchangeRate = (wonExchangeRate/100).toFixed(2)
    hkdExchangeRate = (hkdExchangeRate/100).toFixed(2)
    const updateTime = date + ' ' + time

    money.value = {
      dollarExchangeRate,
      poundExchangeRate,
      euroExchangeRate,
      yenExchangeRate,
      cadExchangeRate,
      wonExchangeRate,
      hkdExchangeRate,
      updateTime
    }

    moneyFinish.value = true
  }

  const btcFinish = ref(false)
  const getBtc = async () => {
    btc.value = await getExchanges.btc().then((res) => res.data.data.price_cny)
    btcFinish.value = true
  }

  onMounted(() => {
    getMoney().then()
    getBtc().then()
  })

  return {
    money,
    btc,
    moneyFinish,
    btcFinish
  }
}
