import Bowser from "bowser";
import { ref } from 'vue'

export default function getUserAgentInfo() {
  const userAgentInfo = ref({
    language: 'N/A',
    doNotTrack: 'N/A',
    browser: {
      name: 'N/A',
      version: 'N/A'
    },
    engine: {},
    os: {
      name: 'N/A',
      versionName: 'N/A'
    },
    platform: {

    }
  })

  const language = window.navigator.language
  const doNotTrack = window.navigator.doNotTrack
  const { browser, engine, os, platform } = Bowser.parse(window.navigator.userAgent)
  userAgentInfo.value = {
    language, browser, engine, os, platform, doNotTrack
  }

  return { userAgentInfo }
}
