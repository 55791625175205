import CryptoJS from 'crypto-es'
import {ref} from 'vue'

const getAudio = async () => {
  return new Promise((resolve, reject) => {
    let audioHashInfo;
    // let audioSumInfo = 0;
    try {
      let context = new (window.OfflineAudioContext || window.webkitOfflineAudioContext)(1, 44100, 44100);
      // Create oscillator
      let pxi_oscillator = context.createOscillator();
      pxi_oscillator.type = "triangle";
      pxi_oscillator.frequency.value = 1e4;
      // Create and configure compressor
      let pxi_compressor = context.createDynamicsCompressor();
      pxi_compressor.threshold && (pxi_compressor.threshold.value = -50);
      pxi_compressor.knee && (pxi_compressor.knee.value = 40);
      pxi_compressor.ratio && (pxi_compressor.ratio.value = 12);
      pxi_compressor.reduction && (pxi_compressor.reduction.value = -20);
      pxi_compressor.attack && (pxi_compressor.attack.value = 0);
      pxi_compressor.release && (pxi_compressor.release.value = .25);
      // Connect nodes
      pxi_oscillator.connect(pxi_compressor);
      pxi_compressor.connect(context.destination);
      // Start audio processing
      pxi_oscillator.start(0);
      context.startRendering().then()
      context.oncomplete = (evnt) => {
        let sha1 = CryptoJS.algo.SHA1.create();
        for (let i = 0; i < evnt.renderedBuffer.length; i++) {
          sha1.update(evnt.renderedBuffer.getChannelData(0)[i].toString());
        }
        let hash = sha1.finalize();
        audioHashInfo = hash.toString(CryptoJS.enc.Hex);
        pxi_compressor.disconnect();
        resolve(audioHashInfo.slice(0, 16))
      }
    } catch (u) {
      reject(u)
    }
  })
}

export default function useAudioPrints() {
  const audioPrints = ref('N/A');

  const fetchAudioPrints = async () => {
    try {
      const result = await getAudio();
      audioPrints.value = result;
    } catch (error) {
      console.error('Failed to fetch audio prints:', error);
    }
  }

  return {
    audioPrints,
    fetchAudioPrints
  }
}