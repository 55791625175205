import {onMounted, ref} from 'vue'

export default function fetchIpWebrtc() {
  const ipWebrtc = ref({
    client: 'N/A',
    public: 'N/A',
    ipv6: 'N/A',
    state: '已关闭'
  })
  const ipWebrtcFinish = ref(0)

  const getIpByWebrtc = new Promise((resolve, reject) => {
    const rtcIp = {
      client: 'N/A',
      public: 'N/A',
      ipv6: 'N/A'
    }
    let ip_dups = {}
    // 兼容chrome和firefox
    let RTCPeerConnection = window.RTCPeerConnection
      || window.mozRTCPeerConnection
      || window.webkitRTCPeerConnection
    // eslint-disable-next-line no-unused-vars
    let useWebKit = !!window.webkitRTCPeerConnection

    // 利用iframe突破webrtc限制，需新建一个iframe命名iframe
    if (!RTCPeerConnection) {
      // eslint-disable-next-line no-undef
      const win = iframe.contentWindow
      RTCPeerConnection = win.RTCPeerConnection
        || win.mozRTCPeerConnection
        || win.webkitRTCPeerConnection
      useWebKit = !!win.webkitRTCPeerConnection
    }
    // 最低需求配置
    let mediaConstraints = {
      optional: [{RtpDataChannels: true}]
    }

    // webrtc服务器配置
    const servers = {
      //iceServers: [{urls: 'stun:stun.l.google.com:19302?transport=udp'}]
      iceServers: [{urls: 'stun:stun.l.google.com:19302'}]
    }

    // 创建RTC连接实例
    const pc = new RTCPeerConnection(servers, mediaConstraints)

    // 处理返回信息
    function handleCandidate(candidate) {
      let ip_addr
      ip_dups[ip_addr] = true
      return new Promise((resolve) => {
        const ip_regex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/
        if (ip_regex.exec(candidate) !== null) {
          ip_addr = ip_regex.exec(candidate) [1]
          resolve(ip_addr)
        }
      })
    }

    function handleIp(ip) {
      if (ip.match(/^(192\.168\.|169\.254\.|10\.|172\.(1[6-9]|2\d|3[01]))/)) {
        console.log('client' + ip)
        rtcIp.client = ip
      } else if (ip.match(/^[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7}$/)) {
        console.log('ipv6' + ip)
        rtcIp.ipv6 = ip
      } else {
        console.log('public' + ip)
        rtcIp.public = ip
      }
    }

    // 创建监听
    try {
      pc.onicecandidate = function (ice) {
          // 过滤无效，处理
          if (ice.candidate) {
            handleCandidate(ice.candidate.candidate).then((res) => {
                handleIp(res)
              }
            )
          }
      }
    } catch (error) {
      reject(error)
    }

    // 伪造一个 data channel
    pc.createDataChannel('noise')

    // 创建一个 offer sdp
    pc.createOffer({}).then((desc) => {
      pc.setLocalDescription(desc).then(() => {
          const lines = pc.localDescription.sdp.split('\n')
          lines.forEach((line) => {
            if (line.indexOf('a=candidate') === 0) {
              handleCandidate(line).then(res => handleIp(res))
            }
          })
          setTimeout(()=>{
            resolve(rtcIp)
          }, 2000)
        }
      )
    }).catch(() => reject('已关闭'))

    // 创建一个超时
    setTimeout(() => {
      reject('已关闭')
    }, 20000)
  })

  onMounted(() => {
    getIpByWebrtc.then((res) => {
      ipWebrtc.value = res
      ipWebrtcFinish.value = 1
      console.log(ipWebrtc.value)
    }).catch(() => {
      ipWebrtcFinish.value = -1
    })
  })

  return {
    ipWebrtc,
    ipWebrtcFinish
  }
}
