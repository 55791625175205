<template>
  <div class="progress">
    <div class="bg">
      <div class="bar">
        <transition>
          <div class="bar-rate" :style="style">
          </div>
        </transition>
        <div class="bar-show">
          <div class="bar-show-init">
            <div class="icon">
              <img src="../assets/img/icon-private.png" width="30" alt="private">
            </div>
            <div class="tips">
              {{ tips }}
            </div>
            <button class="button" @click="progressTipsShow = !progressTipsShow">
              展开详细
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--展开-->
  <transition>
    <div class="progress-tips" v-if="progressTipsShow">
      <ul>
        <li v-for="err in errorsShow" :key="err.key">
          <div class="percent">
            - {{ err.scoreShow }}%
          </div>
          <div class="description">
            <h2>{{ languageSelect.errorReason[err.key].title }}</h2>
            <p>{{ languageSelect.errorReason[err.key].content }}</p>
            <p>浏览器: {{ err.content.client }}, 服务器: {{ err.content.server }}</p>
          </div>
        </li>
      </ul>
    </div>
  </transition>
</template>

<script>
import config from '../utils/config.json'
import {computed, inject, ref, watch, onMounted} from 'vue'
// import getFingerPrints from "../composables/getFingerPrints.js"
import service from "../utils/axiosConfig"

export default {
  name: "TheProgress",
  setup() {
    // 获取参数
    const mbQuery = ref('')
    const postData = ref({})
    onMounted(()=>{
      const query = window.location.search.substring(1)
      const vars = query.split('&')
      for (let i=0; i<vars.length; i++) {
        let pair = vars[i].split('=')
        if (pair[0] === 'wd') {
          mbQuery.value = pair[1]
        } else if (pair[0] === 'str') {
          postData.value.str = pair[1]
        }
      }
    })
    const languageSelect = inject('languageSelect')
    console.log(languageSelect)
    // 进度条文字
    const tips = ref('')
    const color = ref('#42B983')
    // 进度条进度
    const progressWidth = ref(0)
    // 储存对比有问题的项目
    const errors = ref([])

    // 获取设备的时区
    const nowTimezoneClient = inject('nowTimezoneClient')
    // 请求服务器获取IP信息
    const ipInfoServer = inject('ipInfoServer')
    const ipInfoServerFinish = inject('ipInfoServerFinish')

    const userAgentInfo = inject('userAgentInfo')

    // 获取DNS
    const dnsDone = ref(0)
    const dns = inject('dns')
    const dnsFinish = inject('dnsFinish')

    // ip完成
    const ipDone = ref(0)
    const ipWebrtcFinish = inject('ipWebrtcFinish')
    const ipWebrtc = inject('ipWebrtc')

    // 分数
    const grade = ref(100)
    const errorsShow = ref([])
    let fingerPrints = []
    let fingerStr = ''
    // 指纹
    // const { fingerPrints } = getFingerPrints()

    // 对比DNS
    watch(dnsFinish, (newVal) => {
      if (newVal === 1) {
        dnsDone.value++
      } else if (newVal === -2) {
        console.log('获取超时')
        dnsDone.value = -1
      } else {
        console.log('获取失败')
        dnsDone.value = -1
      }
    })

    watch(dnsDone, (newVal) => {
      if (newVal === 2) {
        // 加入候鸟判断
        let dnsCountry
        if (mbQuery.value === 'mb') {
          dnsCountry = ipInfoServer.value.countryCode
        } else {
          dnsCountry = dns.value[0].geo[0].cc
        }
        const ipCountry = ipInfoServer.value.countryCode
        console.log(dnsCountry, ipCountry)
        if (dnsCountry === ipCountry) {
          tips.value = 'DNS对比成功'
        } else {
          errors.value.push(
              {
                title: 'dns',
                content: {
                  server: ipCountry,
                  client: dnsCountry
                }
              }
          )
        }
        progressWidth.value += 20
      } else if (newVal === -1) {
        tips.value = 'DNS获取失败，不参与评分'
        progressWidth.value += 20
      } else {
        tips.value = 'DNS获取成功，等待第二个结果'
      }
    })

    // 观察webrtc是否获得
    watch(ipWebrtcFinish, (newVal) => {
      if (newVal === 1) {
        ipDone.value++
      } else if (newVal === -1) {
        ipDone.value = -1
      }
    })

    // 当ip获得完全后，开始对比
    watch(ipDone, (newVal) => {
      if (newVal === 2) {
        console.log('开始对比ip')
        console.log(ipWebrtc.value)
        // ip对比
        if (ipWebrtc.value.public === 'N/A') {
          tips.value = 'webrtc获取超时或已关闭'
        } else {
          if (ipWebrtc.value.public === ipInfoServer.value.clientIp) {
            tips.value = 'ip对比成功'
            console.log(ipWebrtc.value + ipInfoServer.value.clientIp + 'ip对比成功')
          } else {
            console.log('ip对比失败')
            tips.value = 'ip对比失败'
            errors.value.push(
                {
                  title: 'ip',
                  content: {
                    client: ipWebrtc.value.public,
                    server: ipInfoServer.value.clientIp
                  }
                }
            )
          }
        }
      } else if (newVal === -1) {
        console.log('webrtc获取超时或已关闭')
        tips.value = 'webrtc获取超时或已关闭'
      } else {
        console.log('等待开始ip对比')
        tips.value = '等待开始ip对比'
      }
      progressWidth.value += 20
    })

    // 时区&语言对比
    watch(ipInfoServerFinish, (newVal) => {
      if (newVal) {
        console.log(ipInfoServer.value)

        // ipDone + 1
        ipDone.value++
        // dnsDone ++
        dnsDone.value++
        // 时区对比
        tips.value = '请求服务器获取IP信息成功,时区对比开始..'
        if (nowTimezoneClient.value == ipInfoServer.value.timeZone) {
          tips.value = '时区对比成功'
          console.log(nowTimezoneClient.value + ipInfoServer.value.timeZone + '时区对比成功')
        } else {
          tips.value = '时区对比失败'
          errors.value.push(
              {
                title: 'timezone',
                content: {
                  client: nowTimezoneClient.value,
                  server: ipInfoServer.value.timeZone
                }
              }
          )
        }
        progressWidth.value += 20
        // 语言对比
        tips.value = '语言对比开始'
        const serverLanguage = config.country[ipInfoServer.value.countryCode.toLowerCase()]
        const clientLanguage = userAgentInfo.value.language.slice(0, 2)
        if (serverLanguage !== clientLanguage) {
          tips.value = '语言对比 - 异常'
          errors.value.push(
              {
                title: 'language',
                content: {
                  client: clientLanguage,
                  server: serverLanguage
                }
              }
          )
        } else {
          tips.value = '语言对比 - 正常'
          console.log('语言对比成功' + serverLanguage + clientLanguage)
        }
        progressWidth.value += 20
      }
    })

    // 开始打分
    watch(progressWidth, (newVal) => {
      if (newVal === 100) {
        console.log('*********对比完成，开始打分********')
        tips.value = '对比完成，开始打分'
        let gradeCal = 100
        if (errors.value.length >= 1) {
          errors.value.forEach((err) => {
            switch (err.title) {
              case 'timezone':
                gradeCal -= config.errors.timezone.score
                errorsShow.value.push({
                  key: 'timezone',
                  scoreShow: config.errors.timezone.score,
                  content: err.content
                })
                break
              case 'language':
                gradeCal -= config.errors.language.score
                errorsShow.value.push({
                  key: 'language',
                  scoreShow: config.errors.language.score,
                  content: err.content
                })
                break
              case 'dns':
                gradeCal -= config.errors.dns.score
                errorsShow.value.push({
                  key: 'dns',
                  scoreShow: config.errors.dns.score,
                  content: err.content
                })
                break
              case 'ip':
                gradeCal -= config.errors.ip.score
                errorsShow.value.push({
                  key: 'ip',
                  scoreShow: config.errors.ip.score,
                  content: err.content
                })
                break
              default:
                break
            }
          })
        }
        tips.value = gradeCal
        if (gradeCal >= 80) {
          tips.value = languageSelect.value.errorDes.level.safe + ' ' + gradeCal + '%'
        } else if (gradeCal >= 60) {
          tips.value = languageSelect.value.errorDes.level.low + ' ' + gradeCal + '%'
          color.value = '#FF9900'
        } else {
          tips.value = languageSelect.value.errorDes.level.high + ' ' + gradeCal + '%'
          color.value = '#E84B3F'
        }
        if (postData.value.str) {
          const clientinfo = {
            clientip: ipInfoServer.value.clientIp,
            host: ipInfoServer.value.domain,
            dns: '',
            countryCode: ipInfoServer.value.countryCode,
            os: userAgentInfo.value.os.name+' '+userAgentInfo.value.os.versionName,
            webrtc_public: ipWebrtc.value.public !== 'N/A' && ipWebrtc.value.client !== 'N/A' ? ipWebrtc.value.client : ipWebrtc.value.public,
            browser: userAgentInfo.value.browser.name+' '+userAgentInfo.value.browser.version,
            region_name: ipInfoServer.value.regionName,
            latitude: ipInfoServer.value.latitude,
            longitude: ipInfoServer.value.longitude,
            timezone: ipInfoServer.value.timeZone,
            timezone_text: ipInfoServer.value.timeZoneNext,
            language: userAgentInfo.value.language
          }
          dns.value.forEach((item) => {
            clientinfo.dns += item.ip +','
          })
          clientinfo.dns = clientinfo.dns.substring(0, clientinfo.dns.length-1)
          fingerStr = localStorage.getItem('fingerPrints');
          fingerPrints = JSON.parse(fingerStr);
          postData.value.hsts = fingerPrints.hsts.toUpperCase()
          postData.value.webgl = fingerPrints.webgl.toUpperCase()
          postData.value.canvas = fingerPrints.canvas.toUpperCase()
          postData.value.plugins = fingerPrints.plugins.toUpperCase()
          postData.value.audio = fingerPrints.audio.toUpperCase()
          postData.value.rects = fingerPrints.rects.toUpperCase()
          postData.value.fonts = fingerPrints.fonts.toUpperCase()
          postData.value.clientinfo = encodeURIComponent(JSON.stringify(clientinfo))
          postData.value.security_percent = gradeCal
          postData.value.inweb = 1
          service.post(`https://admin.mbbrowser.com/api/mbbrowser/ussfinger`, postData.value)
        }
      }
    })

    const style = computed(() => {
      const style = {height: '38px'}
      style.width = progressWidth.value + '%'
      style.background = color.value
      style.transition = `width 1s ease-in`
      return style
    })


    return {
      tips,
      progressWidth,
      style,
      errors,
      errorsShow,
      languageSelect,
      grade
    }
  },
  data() {
    return {
      progressTipsShow: false
    }
  },
  computed: {},
  methods: {}
}
</script>

<style scoped>
.progress {
  display: flex;
  justify-content: center;
  padding: 50px 0;
}

.bg {
  height: 50px;
  max-width: 1200px;
  width: 100%;
  padding: 0 6px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50px;
  display: flex;
  align-items: center;
}

.bar {
  height: 38px;
  background: rgba(255, 255, 255, 0.3);
  width: 100%;
  border-radius: 38px;
  position: relative;
}

.bar-rate {
  height: 38px;
  border-radius: 38px;
  max-width: 100%;
  overflow: hidden;
}

.bar-show {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  line-height: 36px;
  color: #FFFFFF;
}

.bar-show .bar-show-init {
  flex: 1;
  padding: 0 15px;
  display: flex;
  font-size: 14px;
}

.bar-show .tips {
  flex: 1;
  text-align: center;
}

.progress-tips {
  background: rgba(0, 0, 0, 0.1);
  color: #FFFFFF;
  margin-top: -30px;
  padding: 30px 0;
}

.progress-tips ul {
  max-width: 1200px;
  margin: 0 auto;
}

.progress-tips ul li {
  display: flex;
  padding-bottom: 20px;
}

.progress-tips ul li .percent {
  width: 80px;
  height: 80px;
  text-align: center;
  line-height: 80px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.2);
  font-size: 18px;
  margin-right: 20px;
}

.progress-tips ul li h2 {
  font-size: 20px;
  padding-bottom: 5px;
}

.button {
  border: none;
  background: none;
  color: #FFFFFF;
}

@media only screen and ( max-width: 468px ) {
  .bar-show .tips {
    font-size: 12px;
  }
}
</style>
