<template>
  <div v-if="adShow" class="ad ad1">
    <a href="https://www.mbbrowser.com/console/console/shop" target="_blank" class="init">
      <img src="../assets/img/2024_s11_banner.png">
    </a>
  </div>
  <div v-if="!adShow" class="ad">
    <a href="https://www.mbbrowser.com/" target="_blank" class="init">
      <img src="../assets/img/Mbbrowser.png" class="pcbanner">
      <img src="../assets/img/Mbbrowser-mobile.png" class="mobilebanner">
    </a>
  </div>
</template>

<script>
export default {
  name: 'AdShow',
  data () {
    return {
      adShow: false
    }
  },
  mounted() {
    if (new Date() > new Date('2024-10-30 00:00:01') && new Date() < new Date('2024-11-12 23:59:59')) {
      this.adShow = true;
    }
    if (window.location.hash === '#adshow') {
      this.adShow = true;
    }
  }
}
</script>

<style scoped>
.ad1{
  background-color: #c30102 !important;
}
.ad {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 64px;
  display: flex;
  justify-content: center;
  text-align: center;
  background: #0d2f81;
}
.ad .init {
  display: block;
  max-width: 1200px;
  width: 100%;
  text-align: center;
}
.ad img {
  width: 100%;
}
.close {
  position: absolute;
  right: 0;
  top: -25px;
  background: rgba(255, 255, 255, .9);
  padding: 2px 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.pcbanner{
  display: block;
}
.mobilebanner{
  display: none;
}
@media only screen and (max-width: 890px) {
  .ad {
    bottom: 0;
  }
  .pcbanner{
    display: none;
  }
  .mobilebanner{
    display: block;
  }
}
</style>
