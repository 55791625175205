<template>
  <div class="finger-prints">
    <header class="finger-title">
      <span>浏览器指纹在线检测</span>
    </header>
    <ul class="finger-prints-list">
      <li>
        <div class="left">
          <img src=".././assets/img/prints-hsts.png" width="60" alt="hsts">
        </div>
        <div class="right">
          <h5 class="title">HSTS</h5>
          <span class="prints-intro">{{ languageSelect.fingerPrints.hsts }}</span>
          <b>{{ fingerPrints.hsts.toUpperCase() }}</b>
        </div>
      </li>
      <li>
        <div class="left">
          <img src=".././assets/img/prints-webgl.png" width="60" alt="hsts">
        </div>
        <div class="right">
          <h5 class="title">WEBGL</h5>
          <span class="prints-intro">{{ languageSelect.fingerPrints.webgl }}</span>
          <b>{{ fingerPrints.webgl.toUpperCase() }}</b>
        </div>
      </li>
      <li>
        <div class="left">
          <img src=".././assets/img/prints-canvas.png" width="60" alt="hsts">
        </div>
        <div class="right">
          <h5 class="title">CANVAS</h5>
          <span class="prints-intro">{{ languageSelect.fingerPrints.canvas }}</span>
          <b>{{ fingerPrints.canvas.toUpperCase() }}</b>
        </div>
      </li>
      <li>
        <div class="left">
          <img src=".././assets/img/prints-plugins.png" width="60" alt="hsts">
        </div>
        <div class="right">
          <h5 class="title">PLUGINS</h5>
          <span class="prints-intro">{{ languageSelect.fingerPrints.plugins }}</span>
          <b>{{ fingerPrints.plugins.toUpperCase() }}</b>
        </div>
      </li>
      <li>
        <div class="left">
          <img src=".././assets/img/prints-rect.png" width="60" alt="hsts">
        </div>
        <div class="right">
          <h5 class="title">RECTS</h5>
          <span class="prints-intro">{{ languageSelect.fingerPrints.rects }}</span>
          <b>{{ fingerPrints.rects.toUpperCase() }}</b>
        </div>
      </li>
      <li>
        <div class="left">
          <img src="../assets/img/prints-font.png" width="60" alt="hsts">
        </div>
        <div class="right">
          <h5 class="title">FONTS</h5>
          <span class="prints-intro">{{ languageSelect.fingerPrints.fonts }}</span>
          <b>{{ fingerPrints.fonts.toUpperCase() }}</b>
        </div>
      </li>
      <li>
        <div class="left">
          <img src=".././assets/img/prints-audio.png" width="60" alt="hsts">
        </div>
        <div class="right">
          <h5 class="title">AUDIO</h5>
          <span class="prints-intro">{{ languageSelect.fingerPrints.audio }}</span>
          <button class="btn" v-if="audioPrints === 'N/A'" :disabled="isLoading" @click="fetchAudioPrints">
            <span v-if="isLoading">Loading...</span>
            <span v-else>{{ languageSelect.fingerPrints.getaudio }}</span>
          </button>
          <b v-else>{{ audioPrints.toUpperCase() }}</b>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import getFingerPrints from "../composables/getFingerPrints.js"
import useAudioPrints  from "../composables/getAudioPrints.js"
import {inject, ref} from "vue";

export default {
  name: "TheFingerPrints",
  setup() {
    const isLoading = ref(false);
    const languageSelect = inject('languageSelect')
    const { fingerPrints } = getFingerPrints()
    const { audioPrints, fetchAudioPrints: fetchAudioPrintsOriginal } = useAudioPrints();
    const fetchAudioPrints = async () => {
      try {
        isLoading.value = true;
        await fetchAudioPrintsOriginal();
      } catch (error) {
        console.error('Failed to fetch audio prints:', error);
      } finally {
        isLoading.value = false;
      }
    }
    return { fingerPrints, languageSelect, audioPrints, fetchAudioPrints, isLoading }
  }
}
</script>

<style scoped>
.finger-prints{
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px 0;
}
.finger-title{
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}
.finger-prints-title{
  padding: 20px 0;
  text-align: center;
}
.finger-prints-title h1{
  font-size: 24px;
  font-weight: 500;
  color: #202124;
  padding-bottom: 5px;
}
.finger-prints-title .intro{
  color: #5f6368;
  font-size: 14px;
}
ul.finger-prints-list{
  display: flex;
  flex-wrap: wrap;
}
ul.finger-prints-list li{
  margin-right: 20px;
  width: 30%;
  display: flex;
  padding: 15px 0;
}
ul.finger-prints-list li .right{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 15px;
  padding-top: 10px;
}
ul.finger-prints-list li .right .prints-intro{
  font-size: 14px;
  color: #71818F;
}
ul.finger-prints-list li .right b{
  font-size: 20px;
  color: #42B983;
  padding-top: 10px;
}
ul.finger-prints-list li .title{
  font-size: 16px;
  display: block;
}

.btn{
  margin-top: 10px;
  background-color: #42B983;
  border: none;
  color: white;
  padding: 8px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #3ca978;
  }
}

.btn[disabled] {
  cursor: wait;
  background-color: #ccc;
}

@media only screen and (max-width: 680px) {
  ul.finger-prints-list li{
    width: 40%;
    padding-left: 20px;
  }
}
@media only screen and (max-width: 480px) {
  ul.finger-prints-list li{
    width: 80%;
  }
}
</style>
